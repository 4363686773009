<template lang="html">
  <div class="about">
    <div class="container">
      <div class="main">
        <h1 class="title underlined">What is it ?</h1>
        <p>
          Spritebooth® is a <b>pixel editor</b> and at the same time <b>a web render engine</b>
          designed to take advantage of browsers built-in technologies such as CSS &amp; Javascript.
        </p>
        <p>
          It allows to <b>both create and display raster-based images right in the browser</b>, and creates a <b>new way of working with images</b>.
        </p>
        <h1 class="title underlined">What benefits ?</h1>
        <h2 class="title-2">Fileless "format"</h2>
        <p>
          Spritebooth® allows to display created illustrations and animations directly <b>into any web page, without having to manipulate any real file</b>.
        </p>
        <p>
          <b>Eliminating the need to manually edit images and animations</b> files makes <b>workflows easier &amp; faster</b>. Also, it can make <b>web projects bundles lighter</b>.
        </p>
        <h2 class="title-2">Self-updating pictures</h2>
        <p>Things are pretty <b>simple and straight-foward : you have a master of your artwork</b> and you can display <b>an infinite number of instances</b> of it on web pages by loading the library and making an API call.</p>
        <p>
          Then, <b>everytime you update your arkwork</b> in the editor, <b>every instances of if automatically update everywhere on the web</b>, without having to do anything more.
        </p>
        <h2 class="title-2">Direct access &amp; Granular control</h2>
        <p>
          By working directly within Spritebooth®, artists can <b>track their artworks</b> over the web and <b>get statistics about how they are used</b>.
        </p>
        <h2 class="title-2">Trackable arworks</h2>
        <p>
          By working directly within Spritebooth®, artists can <b>track the use of their artworks</b> over the web and <b>get detailed statistics</b>.
        </p>
        <hr>
        <small>A project by <a href="https://github.com/ynck-chrl" target="_blank" style="text-decoration: underline">Ynck Chrl</a> - August 2019</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
  data(){
    return {

    }
  },
  beforeMount(){
    // document.getElementById('app').scrollIntoView()
  }
}
</script>

<style lang="css">
.about .container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 64px);
  margin: 0 auto;
}
.about .main{
  max-width : 61.8%;
  margin-bottom: 120px;
  color: var(--over-bg);
}
.about hr{
  margin: 48px 0 16px 0;
  border: 1px dashed var(--over-bg);
}
.about h1 {
  margin-top: 40px;
  margin-bottom: 8px;
  font-weight: 600;
}
.about p{
  margin-top: 8px;
}
</style>
